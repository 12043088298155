.filled-button-background {
  background: linear-gradient(139.67deg, #00a000 24.46%, #14f27a 100%);
  color: white;
}

.max-height-44px {
  min-height: 44px;
  max-height: 44px;
}

.bordered-button-background {
  border: 2px solid #00a000;
}

.disabled-button-background {
  background: #0249d6;
  opacity: 0.6;
  color: white;
}

.top-right {
  top: 80px;
  right: 16px;
}

.bottom-right {
  right: 16px;
  bottom: 16px;
}

.border-radius-16px {
  border-radius: 16px;
}

.floating-button-size {
  height: 48px;
  width: 48px;
}

.button-background-transparent {
  background: rgba(0, 0, 0, 0.5);
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffff;
  color: #ffff;
  box-shadow: 9999px 0 0 -5px #ffff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffff;
  color: #ffff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #ffff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #ffff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

.floating-button-active-icon-position-top-right {
  top: 0;
  right: 0;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #ffff;
  }

  30% {
    box-shadow: 9984px 0 0 2px #ffff;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #ffff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #ffff;
  }

  30% {
    box-shadow: 9999px 0 0 2px #ffff;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #ffff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #ffff;
  }

  30% {
    box-shadow: 10014px 0 0 2px #ffff;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #ffff;
  }
}

.dot-pulse-dark {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cfcfcf;
  color: #cfcfcf;
  box-shadow: 9999px 0 0 -5px #cfcfcf;
  animation: dotPulseDark 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse-dark::before,
.dot-pulse-dark::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cfcfcf;
  color: #cfcfcf;
}

.dot-pulse-dark::before {
  box-shadow: 9984px 0 0 -5px #cfcfcf;
  animation: dotPulseDarkBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-dark::after {
  box-shadow: 10014px 0 0 -5px #cfcfcf;
  animation: dotPulseDarkAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseDarkBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #cfcfcf;
  }

  30% {
    box-shadow: 9984px 0 0 2px #cfcfcf;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #cfcfcf;
  }
}

@keyframes dotPulseDark {
  0% {
    box-shadow: 9999px 0 0 -5px #cfcfcf;
  }

  30% {
    box-shadow: 9999px 0 0 2px #cfcfcf;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #cfcfcf;
  }
}

@keyframes dotPulseDarkAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #cfcfcf;
  }

  30% {
    box-shadow: 10014px 0 0 2px #cfcfcf;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #cfcfcf;
  }
}

@media screen and (min-width: 576px) {
  .top-right {
    top: 116px;
  }

  .adaptive-floating-button-size {
    height: 30px;
    width: 30px;
  }

  .bottom-right {
    right: 36px;
    bottom: 36px;
  }

  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background-color: #ffff;
    color: #ffff;
    box-shadow: 9999px 0 0 -5px #ffff;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  .dot-pulse::before,
  .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background-color: #ffff;
    color: #ffff;
  }
}

.adaptive-floating-button-size {
  height: 30px;
  width: 30px;
}

.dot-pulse-primary {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00a000;
  color: #00a000;
  box-shadow: 9999px 0 0 -5px #00a000;
  animation: dotPulsePrimary 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse-primary::before,
.dot-pulse-primary::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00a000;
  color: #00a000;
}

.dot-pulse-primary::before {
  box-shadow: 9984px 0 0 -5px #00a000;
  animation: dotPulsePrimaryBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-primary::after {
  box-shadow: 10014px 0 0 -5px #00a000;
  animation: dotPulsePrimaryAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulsePrimaryBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #00a000;
  }

  30% {
    box-shadow: 9984px 0 0 2px #00a000;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #00a000;
  }
}

@keyframes dotPulsePrimary {
  0% {
    box-shadow: 9999px 0 0 -5px #00a000;
  }

  30% {
    box-shadow: 9999px 0 0 2px #00a000;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #00a000;
  }
}

@keyframes dotPulsePrimaryAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #00a000;
  }

  30% {
    box-shadow: 10014px 0 0 2px #00a000;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #00a000;
  }
}
