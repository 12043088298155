.refer-friend-bg {
  background: url("../../Assets/background.svg") no-repeat bottom center,
    linear-gradient(
      104.04deg,
      rgba(67, 98, 234, 1) 0%,
      rgba(54, 79, 192, 1) 100%
    );

  background-size: contain;
}

.card-bg-color {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
}

.qr-image-border {
  opacity: "90%";
  border: 20px solid #435ccc;
}
