.otp-input-box-size {
  width: 48px;
  height: 48px;
}

.otp-input-box-size-small {
  width: 36px;
  height: 48px;
}

.max-width-54px {
  max-width: 54px;
}

.border-bottom-otp-box {
  border: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  border-radius: 0;
  border-bottom: 1px solid var(--tertiaryColor);
}

.border-bottom-otp-box:focus {
  outline: none;
  border: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  border-radius: 0;
  border-bottom: 1px solid var(--primaryColor);
}

.border-bottom-otp-box-error {
  outline: none;
  border: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  border-radius: 0;
  border-bottom: 1px solid #e64539;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: textfield; /* Safari and Chrome */
  appearance: textfield;
}

@media only screen and (max-width: 380px) {
  .otp-input-box-size {
    height: 38px;
    width: 38px;
  }
}
