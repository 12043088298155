.reactMarkDown > p > span > img {
  width: 100%;
  border-radius: 8px;
}

.reactMarkDown > p > img {
  width: 100%;
  border-radius: 8px;
}

.reactMarkDown > p,
ol,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding-bottom: 15px;
}

.reactMarkDown > ol {
  padding-left: 15px;
}

.reactMarkDown > p > a {
  color: #4362ea;
}

.reactMarkDown > pre {
  overflow-x: scroll;
  margin-bottom: 15px;
  background-color: #4e4e4e44;
}

.reactMarkDown > table > thead {
  text-align: left;
  border-bottom: solid 1px #e5e5e5;
}

.reactMarkDown > table > thead > tr > th {
  border-bottom: solid 1px #e5e5e5;
  padding-left: 15px;
  padding-bottom: 15px;
}

.reactMarkDown > table > tbody > tr > td {
  vertical-align: initial;
  width: 50%;
  padding: 15px;
  border-bottom: solid 1px #e5e5e5;
}

.reactMarkDown > table > tbody > tr:last-child td {
  border-bottom: none;
}

.reactMarkDown > table {
  width: 100%;
  border: solid 1px #e5e5e5;
  border-spacing: 24px;
  margin-bottom: 15px;
  border-radius: 8px;
}


.qr-loader {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  text-indent: -9999em;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: blue;
  background: -moz-linear-gradient(left, blue 10%, rgba(0, 200, 128, 0) 42%);
  background: -webkit-linear-gradient(left, blue 10%, rgba(0, 200, 128, 0) 42%);
  background: -o-linear-gradient(left, blue 10%, rgba(0, 200, 128, 0) 42%);
  background: -ms-linear-gradient(left, blue 10%, rgba(0, 200, 128, 0) 42%);
  background: linear-gradient(to right, blue 10%, rgba(0, 200, 128, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.qr-loader:before {
  width: 50%;
  height: 50%;
  background: blue;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.qr-loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}