.gradient-border {
  background: radial-gradient(
        circle at 100% 100%,
        #ffffff 0,
        #ffffff 2px,
        transparent 2px
      )
      0% 0%/4px 4px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 2px, transparent 2px)
      100% 0%/4px 4px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 2px, transparent 2px)
      0% 100%/4px 4px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 2px, transparent 2px) 100%
      100%/4px 4px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 4px)
      calc(100% - 8px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 8px)
      calc(100% - 4px) no-repeat,
    linear-gradient(#f6f6f6 0%, #e2e2e2 50%);
  border-radius: 4px;
  box-sizing: border-box;
}
