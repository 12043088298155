.height-64px {
  height: 64px;
}

.height-width-40px {
  height: 40px;
  width: 40px
}

.background-color-4e6be9 {
  background-color: #4E6BE9;
}