.height-width-4px {
  height: 4px;
  width: 4px;
}

.blog-line-height {
  line-height: 1.8;
}

.height-500px {
  height: 500px;
}

.height-50px {
  height: 50px;
}

.height-300px {
  height: 300px;
}

.width-80px {
  width: 80px;
}

.margin-bottom-20px {
  margin-bottom: 20px;
}

.border-top-left-default {
  border-top-left-radius: 8px;
}

.border-bottom-left-default {
  border-bottom-left-radius: 8px;
}

.latestPost-display-gird-blog {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-evenly;
}

.position-sticky-trending {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  max-height: 50vh;
}

@media screen and (min-width: 1310px) {
  .web-font-size-larger {
    font-size: 20px;
  }
}

@media screen and (min-width: 200px) and (max-width: 600px) {
  .mob-width-selectedBlog {
    width: calc(100% - 1px);
  }
}

/* @media screen and (min-width: 900px) {
  .web-width-60-percent {
    width: 60%;
  }
} */
