.height-2px {
  height: 2px;
}

.width-40px {
  width: 40px;
}

.nav-bar-selected-background{
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 90%);
}

.border-bottom-white-2px{
  border-bottom: 2px solid white;
}